import { primaryactivity } from "_reducers/primaryactivity.reducer";
import moment from "moment";

export function checkIsIncorpify(code) {
  return ["INC_LLCIncorporation", "INC_LLCIncorporationV2"].includes(code);
}

export function checkIsIncStates(code) {
  return ["INC_States_LLCIncorporation", "INC_States_LLCIncorporationV2"].includes(code);
}

export function checkIsIncLLCIncorporation(code) {
  return [
    "INC_LLCIncorporation",
    "INC_LLCIncorporationV2",
    "INC_States_LLCIncorporation",
    "INC_States_LLCIncorporationV2",
  ].includes(code);
}

export function checkIsLLCIncorporation(code) {
  return [
    "EIN_LLCIncorporation",
    "INC_LLCIncorporation",
    "INC_LLCIncorporationV2",
    "INC_States_LLCIncorporation",
    "INC_States_LLCIncorporationV2",
  ].includes(code);
}

export function checkIsRA(code) {
  return [
    "INC_RegisteredAgent",
    "INC_States_RegisteredAgent",
    "EIN_RegisteredAgent",
    "INC_ResidentAgent",
    "INC_States_ResidentAgent",
    "EIN_ResidentAgent",
  ].includes(code);
}

export function checkIsAR(code) {
  return ["INC_AnnualReport", "INC_States_AnnualReport", "EIN_AnnualReport"].includes(code);
}

export function checkIsACR(code) {
  return ["INC_AnnualComplianceReport", "INC_States_AnnualComplianceReport", "EIN_AnnualComplianceReport"].includes(
    code
  );
}

export function checkIsSTP(code) {
  return ["INC_SalesTaxPermit", "INC_States_SalesTaxPermit"].includes(code);
}

export function checkIsBOI(code) {
  return ["INC_BOI", "INC_States_BOI", "EIN_BOI"].includes(code);
}

export function checkIsStateIncorporationFee(code) {
  return ["INC_StateIncorporationFee", "INC_States_StateIncorporationFee", "EIN_StateIncorporationFee"].includes(code);
}

export function checkIsRegisterTrademark(code) {
  return [
    "TRADEMARK_RegisterTrademark",
    "TRADEMARK_RegisterTrademarkV2",
    "INC_RegisterTrademark",
    "INC_States_RegisterTrademark",
  ].includes(code);
}

export function checkIsTrademarkMonitoring(code) {
  return ["TRADEMARK_Trademark_Monitoring", "INC_Trademark_Monitoring"].includes(code);
}

export function checkIsUSPTOFilingFee(code) {
  return ["TRADEMARK_USPTOFilingFee", "INC_USPTOFilingFee", "INC_States_USPTOFilingFee"].includes(code);
}

export function checkIsIncEin(code) {
  return ["INC_EIN", "INC_States_EIN"].includes(code);
}

export function checkIsEinLlc(code) {
  return ["EIN_LLC"].includes(code);
}

export function getProductInitData(codeObj, oldData = {}) {
  let initObj = null;

  switch (codeObj?.code) {
    case "INC_EIN":
    case "INC_States_EIN":
    case "EIN_LLC":
      initObj = {
        legalName: "",
        dbaname: "",
        taxClassification: "Individual",
        owner: {
          firstName: "",
          middleName: "",
          lastName: "",
          suffixName: null,
          title: "",
          ssn: null,
        },
        altAddress: null,
        // {
        //   country: "",
        //   address1: "",
        //   address2: "",
        //   city: "",
        //   state: "",
        //   zip: "",
        //   county: "",
        //   country: "US",
        // },
        corpAddress: {
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          county: "",
          country: "US",
        },
        organizedState: "",
        startDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
        closingMonth: 1,

        activityPrimaryActivity: "started_business",
        activitySpecificProducts: null,
        productDescription: null,
        productExtraQuestion1: null,
        productExtraQuestion2: null,
        productExtraQuestion3: null,

        questionsATF: false,
        questionsAppliedBefore: false,
        questionsExcise: false,
        questionsGambling: false,
        questionsHighway: false,
        questionsHire: false,
        payLessThan4k: false,
        payEmployeesViaPayroll: false,
        annualTaxes: false,
        acceptCreditCardPayments: false,
        husbandAndWife: false,
        multiMember: false,

        numberOfMembers: 1,
        numberOfAgricultureEmployees: 0,
        numberOfHouseholdeEmployees: 0,
        numberOfOtherEmployees: 0,

        previousEINNumberFirst2: null,
        previousEINNumberLast7: null,

        firstDateWages: null,
      };
      break;

    case "INC_BOI":
    case "INC_States_BOI":
      initObj = { dateOfBirth: null, ssn: null, driverLicenceOrID: null };
      break;

    case "EIN_CertifiedCopyStateFee":
    case "EIN_CertifiedCopy":
    case "INC_CertifiedCopyStateFee":
    case "INC_CertifiedCopy":
    case "INC_States_CertifiedCopyStateFee":
    case "INC_States_CertifiedCopy":
    case "INC_States_DBA_State_Fee":
    case "INC_DBA_State_Fee":
    case "EIN_DBA_State_Fee":
    case "INC_States_DBA":
    case "INC_DBA":
    case "EIN_DBA":
    case "INC_CertificateOfGoodStanding":
    case "INC_CertificateOfGoodStandingStateFee":
    case "INC_States_CertificateOfGoodStanding":
    case "INC_States_CertificateOfGoodStandingStateFee":
      initObj = { state: "" };
      break;

    case "EIN_ResidentAgent":
      initObj = {
        state: null,
        webFileNumber: null,
        // processingResult: {
        //   companyName: null,
        //   email: null,
        // },
      };
      break;

    default:
      initObj = {};
      break;
  }

  return { ...initObj, ...oldData, code: codeObj };
}

export function fillProductInitData(initObj, orderDetails) {
  const getFieldsForEinLlc = () => {
    const llcIncorporationProduct = orderDetails?.products?.find(
      (product) => checkIsIncorpify(product?.code?.code) || checkIsIncStates(product?.code?.code)
    );

    if (!llcIncorporationProduct) {
      return {};
    }

    return {
      legalName: llcIncorporationProduct?.companyName || initObj?.legalName,
      acceptCreditCardPayments: llcIncorporationProduct?.acceptCreditCardPayments || initObj?.acceptCreditCardPayments,
      activityPrimaryActivity: llcIncorporationProduct?.activityPrimaryActivity || initObj?.activityPrimaryActivity,
      activitySpecificProducts: llcIncorporationProduct?.activitySpecificProducts || initObj?.activitySpecificProducts,
      productDescription: llcIncorporationProduct?.productDescription || initObj?.productDescription,
      productExtraQuestion1: llcIncorporationProduct?.productExtraQuestion1 || initObj?.productExtraQuestion1,
      productExtraQuestion2: llcIncorporationProduct?.productExtraQuestion2 || initObj?.productExtraQuestion2,
      productExtraQuestion3: llcIncorporationProduct?.productExtraQuestion3 || initObj?.productExtraQuestion3,
      corpAddress: llcIncorporationProduct?.corporateAddress || initObj?.corpAddress,
      altAddress: llcIncorporationProduct?.mailingAddress || initObj?.altAddress,
      numberOfMembers: llcIncorporationProduct?.members?.length || initObj?.numberOfMembers,
      owner: llcIncorporationProduct?.owner || initObj?.owner,
      organizedState: llcIncorporationProduct?.organizedState || initObj?.organizedState,
      startDate: llcIncorporationProduct?.incorporatedDate
        ? moment(llcIncorporationProduct?.incorporatedDate).format("YYYY-MM-DDTHH:mm:ss")
        : initObj?.startDate,
    };
  };

  const getFieldsForStateFee = () => {
    const llcIncorporationProduct = orderDetails?.products?.find(
      (product) => checkIsIncorpify(product?.code?.code) || checkIsIncStates(product?.code?.code)
    );

    if (!llcIncorporationProduct) {
      return {};
    }

    return {
      state: llcIncorporationProduct?.organizedState || initObj?.state,
    };
  };

  const getFieldsForEinResidentAgent = () => {
    const llcIncorporationProduct = orderDetails?.products?.find((product) =>
      checkIsLLCIncorporation(product?.code?.code)
    );

    const einLlcProduct = orderDetails?.products?.find((product) => checkIsEinLlc(product?.code?.code));

    if (!llcIncorporationProduct && !einLlcProduct) {
      return {};
    }

    return {
      state: llcIncorporationProduct?.organizedState || einLlcProduct?.organizedState || initObj?.state,
      webFileNumber: initObj?.webFileNumber,
      // processingResult: {
      //   companyName:
      //     llcIncorporationProduct?.companyName || einLlcProduct?.legalName || initObj?.processingResult?.companyName,
      //   email:
      //     llcIncorporationProduct?.principalOfficer?.email ||
      //     einLlcProduct?.principalOfficer?.email ||
      //     initObj?.processingResult?.email,
      // },
    };
  };

  switch (initObj?.code?.code) {
    case "INC_EIN":
    case "INC_States_EIN":
    case "EIN_LLC":
      return { ...initObj, ...getFieldsForEinLlc() };

    case "EIN_CertifiedCopyStateFee":
    case "EIN_CertifiedCopy":
    case "INC_CertifiedCopyStateFee":
    case "INC_CertifiedCopy":
    case "INC_States_CertifiedCopyStateFee":
    case "INC_States_CertifiedCopy":
    case "INC_States_DBA_State_Fee":
    case "INC_DBA_State_Fee":
    case "EIN_DBA_State_Fee":
    case "INC_States_DBA":
    case "INC_DBA":
    case "EIN_DBA":
    case "INC_CertificateOfGoodStanding":
    case "INC_CertificateOfGoodStandingStateFee":
    case "INC_States_CertificateOfGoodStanding":
    case "INC_States_CertificateOfGoodStandingStateFee":
      return { ...initObj, ...getFieldsForStateFee() };

    case "EIN_ResidentAgent":
      return { ...initObj, ...getFieldsForEinResidentAgent() };

    default:
      return initObj;
  }
}
