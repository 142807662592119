import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import NotificationSystem from "react-notification-system";

import { userActions, alertActions } from "_actions";
import { history } from "_helpers";

import Card from "components/Card/Card.jsx";

import { style } from "variables/Variables.jsx";

class Login extends Component {
  constructor(props) {
    super(props);

    history.listen(() => {
      // clear alert on location change
      this.props.clearAlerts();
    });

    if (localStorage.getItem("user")) {
      // reset login status
      this.props.logout();
    }

    this.state = {
      username: "",
      password: "",
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const formData = new FormData(document.getElementById("login-form"));
    const { username, password } = this.state;

    const uname = username || formData.get("username");
    const upass = password || formData.get("password");

    this.setState({ submitted: true, username: uname, password: upass });

    if (uname && upass) {
      this.props.login(uname, upass);
    }
  }

  shownotification(type, message) {
    this.refs.notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: <div>{message}</div>,
      level: type,
      position: "tr",
      autoDismiss: 3600,
    });
    this.props.clearAlerts();
  }

  componentDidMount() {
    const { alert } = this.props;

    alert?.message && this.refs.notificationSystem && this.shownotification("error", alert.message);
  }

  componentDidUpdate() {
    const { alert } = this.props;

    alert?.message && this.refs.notificationSystem && this.shownotification("error", alert.message);
  }

  render() {
    const { alert } = this.props;
    const { username, password, submitted } = this.state;

    return (
      <div className="content">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Container fluid>
          <Row className="justify-content-center mt-5">
            <Col xs={10} md={6} lg={4}>
              {/* {alert.message && this.refs.notificationSystem && this.shownotification("error", alert.message)} */}
              <Card
                title="Login"
                content={
                  <Form id="login-form" autoComplete="on" onSubmit={this.handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        autoComplete="username"
                        placeholder="Username"
                        name="username"
                        value={username}
                        onChange={this.handleChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        autoComplete="current-password"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                      Login
                    </Button>
                  </Form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
  clearAlerts: alertActions.clear,
};

const connectedLoginPage = connect(mapState, actionCreators)(Login);

export { connectedLoginPage as Login };
