import React from "react";
import { Col, Form, Row } from "react-bootstrap";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import StatusEdit from "../../Status/Edit";
import Address from "../Address/Edit";

import { state_array, TAB_CODES } from "variables/Variables";

function FormResidentAgent({ orderDetails, selectedProduct, onError, onChange }) {
  const productDetails = orderDetails?.products[selectedProduct];

  const handleAddError = (field) => onError(TAB_CODES.llcIncInfo, field);

  const handleProductChange = ({ target: { name, value } }) =>
    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, [name]: value } : product
      ),
    });

  const handleChangeProcessingResult = ({ target: { name, value } }) =>
    handleProductChange({
      target: { name: "processingResult", value: { ...productDetails.processingResult, [name]: value } },
    });

  const handleChangeAddress = (value) => handleChangeProcessingResult({ target: { name: "raAddress", value } });

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">Resident Agent</h4>
      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
      />
      {/* {productDetails?.code?.category === "EIN_INC" && (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Webfile Number:
          </Form.Label>
          <Col sm={7}>
            <Form.Control
              required={productDetails?.state === "TX" && processingResult?.status !== "MissingWebFile"}
              size="sm"
              type="text"
              name="webFileNumber"
              pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
              value={productDetails?.webFileNumber || ""}
              onChange={handleProductChange}
              onInvalid={() => handleAddError("Webfile Number")}
            />
            <Form.Control.Feedback type="invalid">
              Webfile Number should be filled for Texas orders.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      )} */}
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Company Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="companyName"
            pattern="^[ a-zA-Z0-9\.\/,\\;\-:_'~˜`’\(\)\x22\[\]\?<>!\{\}@#\$%\^&\*\+=]+$" // \x22 is "
            value={productDetails?.processingResult?.companyName || ""}
            onChange={handleChangeProcessingResult}
            onInvalid={() => handleAddError("Company Name")}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Email:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="email"
            name="email"
            placeholder="example@mail.com"
            value={productDetails?.processingResult?.email || ""}
            onChange={handleChangeProcessingResult}
            onInvalid={() => handleAddError("Email")}
          />
        </Col>
      </Form.Group>
      <FormPDF productDetails={productDetails} />
      <hr />
      <h6 className="font-weight-bold mt-4">State</h6>
      {state_array[productDetails.state] || "—"}
      <hr />
      <h6 className="font-weight-bold mt-4">Billed (Annually)</h6>${parseFloat(productDetails.price || "0").toFixed(2)}
      <hr />
      <h6 className="font-weight-bold mt-4">Resident Agent Address</h6>
      <Address address={productDetails?.processingResult?.raAddress} onChange={handleChangeAddress} />
    </div>
  );
}

export default FormResidentAgent;
