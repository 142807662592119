import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { getAvailableCategory, history, parseQueryString } from "_helpers";
import { userService } from "_services";
import { alertActions } from "_actions";
import { userConstants } from "_constants";

import Spinner from "components/Spinner";

const Redirect2FA = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const params = parseQueryString(search);
  const { duo_code, state } = params;

  const user = JSON.parse(localStorage.getItem("user"));

  const handleLoginSuccess = (userDetails) => {
    const categoryToRedirect = getAvailableCategory(userDetails);

    dispatch({ type: userConstants.LOGIN_SUCCESS, user: userDetails });
    history.push(categoryToRedirect ? `/admin/orderlists/${categoryToRedirect}` : "/admin/dashboard");
  };

  const handleLoginFailure = (error) => {
    dispatch({ type: userConstants.LOGIN_FAILURE });
    dispatch(alertActions.error(`Invalid auth request: ${error?.message || error?.toString()}`));
    history.push("/login");
  };

  const processDuoCallback = async (token, duo_code, state) => {
    try {
      await userService.duoCallback({ token, duo_code, state });
      const userDetails = await userService.getme(user);
      handleLoginSuccess(userDetails);
    } catch (error) {
      handleLoginFailure(error);
    }
  };

  useEffect(() => {
    const handleInvalidRequest = (message) => {
      dispatch(alertActions.error(message));
      history.push("/login");
    };

    if (!duo_code || !state) {
      return handleInvalidRequest("Invalid auth request");
    }

    if (user?.token) {
      processDuoCallback(user.token, duo_code, state);
    } else {
      handleInvalidRequest("User not authenticated");
    }
  }, [dispatch, search]);

  return (
    <div>
      <Spinner />
    </div>
  );
};

export default Redirect2FA;
