import { fromBlob } from "file-type/browser";

import { history } from "./history";
import { userService } from "_services";

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401 || response.status === 403) {
        // localStorage.removeItem("user");
        history.push("/login");
      }

      if (response.status === 409) {
        history.replace(`${history.location.pathname}?modal=refreshModal`);
      }

      const error =
        (data && data.message) || (Array.isArray(data?.errors) && { errors: data.errors }) || response.statusText;

      return Promise.reject(error);
    } else {
      if (response?.status === 204 && response?.url?.includes("charge-uspto-fee")) {
        return { code: 204, message: "UPSTO fee charge was scheduled" };
      }

      if (response?.status === 204 && response?.url?.includes("charge-amendment")) {
        return { code: 204, message: "Amendment charge was scheduled" };
      }

      if (response?.status === 204 && response?.url?.includes("clear-personal-info")) {
        return { code: 204, message: "Customer info was cleared", success: true };
      }

      if (response?.status === 204 && response?.url?.includes("unlock-product")) {
        return { code: 204, message: "Order added to queue for processing", success: true };
      }

      return data;
    }
  });
}

export function saveFile(response, filename, useNameFromHeader, checkMime) {
  const name = response.headers.get("content-disposition").split("filename=")[1].split(";")[0].replace(/\"/g, "");
  let resultName = "";

  response
    .blob()
    .then(async (blob) => {
      const type = await fromBlob(blob);
      return { blob, type };
    })
    .then(({ blob, type }) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;

      if (useNameFromHeader && name) {
        resultName = name;
      } else {
        resultName = filename;
      }

      if (checkMime && type?.ext) {
        if (resultName?.split(".")?.slice(-1)[0] !== type?.ext) {
          resultName += `.${type?.ext}`;
        }
      }

      link.setAttribute("download", resultName);

      // if (useNameFromHeader) {
      //   if (type?.ext && name?.split(".")?.slice(-1)[0] !== type?.ext) {
      //     link.setAttribute("download", (name || filename) + "." + type?.ext);
      //   } else {
      //     link.setAttribute("download", useNameFromHeader ? name || filename : filename);
      //   }
      // } else if (type?.ext && filename?.split(".")?.slice(-1)[0] !== type?.ext) {
      //   link.setAttribute("download", filename + "." + type?.ext);
      // } else {
      //   link.setAttribute("download", useNameFromHeader ? name || filename : filename);
      // }

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });

  return true;
}

export function handleError(response) {
  const error = response;

  if (error && error?.message?.errors?.length) {
    return { message: error.message.errors[0]?.toString() };
  }

  if (error && !error?.message?.errors?.length && error?.errors?.length) {
    return { message: error.errors[0]?.toString() };
  }

  if (error && !error?.message?.errors && error?.message) {
    const message = error.message?.toString();

    if (message === "Card Declined") {
      return { message: "Uh oh - your card on file has been declined. Please update your payment method to proceed." };
    }

    return { message };
  }

  if (error && !error?.message?.errors && !error?.message && error?.code) {
    return { message: `${error.code?.toString()} Something went wrong` };
  }

  if (error && !error?.message?.errors && !error?.message && !error?.code && error?.toString() === "[object Blob]") {
    return { message: "An error occurred while downloading the file" };
  }

  return { message: error?.toString() };
}
