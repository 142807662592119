import { Row, Col, Form } from "react-bootstrap";

import { MONTHLY_VOLUME } from "variables/Variables";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import StatusEdit from "../../Status/Edit";

function FormCreditCardProcessing({ orderDetails, selectedProduct, onChange, onError, ...props }) {
  const productDetails = orderDetails?.products[selectedProduct];

  const handleProductChange = ({ target: { name, value, checked } }) => {
    let exFields = { [name]: value || null };

    if (["online", "inPerson"].includes(name)) {
      exFields = { ...exFields, [name]: checked };
    }

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, ...exFields } : product
      ),
    });
  };

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">Credit Card Processing</h4>

      <StatusEdit orderDetails={orderDetails} selectedProduct={selectedProduct} onChange={onChange} />

      <FormPDF productDetails={productDetails} {...props} />

      <hr />
      <h6>Credit Card Processing Types</h6>
      <Form.Group as={Row}>
        <Col className="d-flex">
          <Form.Check
            id="checkbox-online"
            type="checkbox"
            name="online"
            checked={productDetails?.online ? true : false}
            onChange={handleProductChange}
          />
          <Form.Label htmlFor="checkbox-online">Online</Form.Label>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col className="d-flex">
          <Form.Check
            id="checkbox-inPerson"
            type="checkbox"
            name="inPerson"
            checked={productDetails?.inPerson ? true : false}
            onChange={handleProductChange}
          />
          <Form.Label htmlFor="checkbox-inPerson">In-Person</Form.Label>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="5">
          Monthly Sales Volume:
        </Form.Label>
        <Col sm="7">
          <Form.Control
            size="sm"
            as="select"
            name="monthlyVolume"
            value={productDetails?.monthlyVolume || ""}
            onChange={handleProductChange}
          >
            <option value="">Select option...</option>
            {Object.keys(MONTHLY_VOLUME)?.map((code) => (
              <option key={code} value={code}>
                {MONTHLY_VOLUME[code] || "Unknown value"}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>
    </div>
  );
}

export default FormCreditCardProcessing;
