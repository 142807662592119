import moment from "moment";
import { useState, useRef } from "react";
import { Form, Modal, Button } from "react-bootstrap";

import DateEdit from "components/OrderDetails/ProductDetails/Status/Date/Edit";

export default function ChangePendingStatusModal({
  show,
  processingStatuses = [],
  productDetails = {},
  handleClose = () => {},
  handleConfirm = () => {},
}) {
  const formEl = useRef(null);
  const [validated, setValidated] = useState(false);
  const [pendingReason, setPendingReason] = useState("");
  const [dateToChangeStatus, setDateToChangeStatus] = useState(moment().format("YYYY-MM-DD"));
  const [pendingStatus, setPendingStatus] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = formEl.current;

    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      setValidated(false);
      handleConfirm({ pendingReason, dateToChangeStatus, pendingStatus });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target || {};

    if (name === "pendingReason") {
      setPendingReason(value);
    }

    if (name === "pendingStatus") {
      setPendingStatus(value);
    }

    if (name === "dateToChangeStatus") {
      setDateToChangeStatus(value);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="md" centered>
      <Modal.Header closeButton>Pending Status Details</Modal.Header>
      <Modal.Body>
        <Form validated={validated} noValidate ref={formEl}>
          <Form.Group>
            <Form.Label>Pending what?</Form.Label>
            <Form.Control
              required
              name="pendingReason"
              placeholder="Enter reason"
              value={pendingReason}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">Please, provide correct Description value</Form.Control.Feedback>
          </Form.Group>
          <DateEdit
            column
            name="dateToChangeStatus"
            label="Change status on what date?"
            date={dateToChangeStatus}
            onChange={handleChange}
          />
          <Form.Group>
            <Form.Label>Change status to?</Form.Label>
            <Form.Control
              required
              size="sm"
              as="select"
              name="pendingStatus"
              value={pendingStatus}
              onChange={handleChange}
            >
              <option value="" />
              {processingStatuses
                ?.filter(({ code }) => code !== "Pending" && code !== "Lead" && code !== "Error")
                ?.filter(({ code }) => {
                  if (code === "Rejected") {
                    return ![
                      "INC_LLCIncorporation",
                      "INC_LLCIncorporationV2",
                      "INC_LLCIncorporationV3",
                      "INC_States_LLCIncorporation",
                      "INC_States_LLCIncorporationV2",
                      "INC_States_LLCIncorporationV3",
                      "EIN_LLCIncorporation",
                      "EIN_LLCIncorporationV2",
                      "EIN_LLCIncorporationV3",
                    ].includes(productDetails?.code?.code);
                  }

                  if (code === "AttorneyReview") {
                    return productDetails?.code?.category === "TRADEMARKS";
                  }

                  return true;
                })
                ?.map(({ code, value }) => (
                  <option key={code} value={code}>
                    {value}
                  </option>
                ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">Please, select next processing status</Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
