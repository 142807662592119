import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import NotificationSystem from "react-notification-system";

import { AdminNavbar } from "components/Navbars/AdminNavbar";
import { Sidebar } from "components/Sidebar/Sidebar";
import ReloadModal from "components/ReloadModal";
import RefreshModal from "components/RefreshModal";

import { uiActions, variantsActions } from "_actions";
import { parseQueryString } from "_helpers";

import { style } from "variables/Variables.jsx";

import routes from "routes/routes.js";

import image from "assets/img/sidebar-1.jpg";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      image: image,
      color: "black",
      hasImage: true,
      isOpenNavBar: false,
    };
  }
  handleNotificationClick = (position, type, message) => {
    this.refs.notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: <div>{message}</div>,
      level: type,
      position: position,
      autoDismiss: 3600,
    });
  };
  getRoutes = (routes) => {
    const userInfo = JSON.parse(localStorage.getItem("user"));

    return routes.map((prop, key) => {
      const conditions = [
        !!prop.allowedRoles && !prop.allowedRoles?.includes(userInfo.role),
        !!prop.category && prop.category !== "ALL" && !userInfo.availableCategories?.includes(prop.category),
      ];
      if (conditions.some((condition) => condition)) {
        return null;
      }

      if (prop.views) {
        return this.getRoutes(prop.views);
      }

      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} handleClick={this.handleNotificationClick} />}
            key={key}
            exact
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (this.props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "";
  };

  handleNavBar = (state) => {
    this.setState(({ isOpenNavBar }) => ({ isOpenNavBar: state !== undefined ? state : !isOpenNavBar }));
  };

  componentDidMount() {
    this.props.getHash();
    this.props.getValuesByCategories();

    this.setState({ _notificationSystem: this.refs.notificationSystem });
  }

  componentDidUpdate(prevProps) {
    if (
      window.innerWidth < 993 &&
      prevProps.history.location.pathname !== prevProps.location.pathname &&
      this.state.isOpenNavBar
    ) {
      this.setState(() => ({ isOpenNavBar: false }));
    }

    if (prevProps.history.location.pathname !== prevProps.location.pathname) {
      this.props.getHash();
      this.refs.notificationSystem.clearNotifications();
    }

    if (prevProps.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  render() {
    const { image, color, hasImage } = this.state;
    const { location, ui } = this.props;
    const { isUiUpdated } = ui;
    const { modal } = parseQueryString(location.search);
    const isDataOutdated = modal === "refreshModal";

    return (
      <div className={`wrapper${this.state.isOpenNavBar ? " nav-open" : ""}`}>
        <NotificationSystem ref="notificationSystem" style={style} />
        <Sidebar
          {...this.props}
          isOpen={this.state.isOpenNavBar}
          routes={routes}
          image={image}
          color={color}
          hasImage={hasImage}
          handleClick={this.handleNotificationClick}
          handleNavBar={this.handleNavBar}
        />
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <AdminNavbar {...this.props} brandText={this.getBrandText()} handleNavBar={this.handleNavBar} />
          <Switch>
            {this.getRoutes(routes)}
            <Route path="*" render={() => <Redirect to="/" />} />
          </Switch>
        </div>
        {isUiUpdated && <ReloadModal />}
        {isDataOutdated && <RefreshModal />}
      </div>
    );
  }
}

const mapStateToProps = ({ ui }) => ({ ui });
const mapDispatchToProps = (dispatch) => ({
  getHash: () => dispatch(uiActions.getHash()),
  getValuesByCategories: () => dispatch(variantsActions.getProcessingValuesByCategories()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));
