var defaultWidth = window.screen.width > 768 ? (window.screen.width * 1) / 3 : window.screen.width;

var style = {
  Wrapper: {},
  Containers: {
    DefaultStyle: {
      position: "fixed",
      width: defaultWidth,
      padding: "10px 10px 10px 20px",
      zIndex: 9998,
      WebkitBoxSizing: "",
      MozBoxSizing: "",
      boxSizing: "",
      height: "auto",
      display: "inline-block",
      border: "0",
      fontSize: "14px",
      WebkitFontSmoothing: "antialiased",
      fontFamily: '"Roboto","Helvetica Neue",Arial,sans-serif',
      fontWeight: "400",
      color: "#FFFFFF",
    },

    tl: {
      top: "0px",
      bottom: "auto",
      left: "0px",
      right: "auto",
    },

    tr: {
      top: "0px",
      bottom: "auto",
      left: "auto",
      right: "0px",
    },

    tc: {
      top: "0px",
      bottom: "auto",
      margin: "0 auto",
      left: "50%",
      marginLeft: -(defaultWidth / 2),
    },

    bl: {
      top: "auto",
      bottom: "0px",
      left: "0px",
      right: "auto",
    },

    br: {
      top: "auto",
      bottom: "0px",
      left: "auto",
      right: "0px",
    },

    bc: {
      top: "auto",
      bottom: "0px",
      margin: "0 auto",
      left: "50%",
      marginLeft: -(defaultWidth / 2),
    },
  },

  NotificationItem: {
    DefaultStyle: {
      position: "relative",
      width: "100%",
      cursor: "pointer",
      borderRadius: "4px",
      fontSize: "14px",
      margin: "10px 0 0",
      padding: "10px",
      display: "block",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
      opacity: 0,
      transition: "all 0.5s ease-in-out",
      WebkitTransform: "translate3d(0, 0, 0)",
      transform: "translate3d(0, 0, 0)",
      willChange: "transform, opacity",

      isHidden: {
        opacity: 0,
      },

      isVisible: {
        opacity: 1,
      },
    },

    success: {
      borderTop: 0,
      backgroundColor: "#a1e82c",
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    error: {
      borderTop: 0,
      backgroundColor: "#fc727a",
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    warning: {
      borderTop: 0,
      backgroundColor: "#ffbc67",
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    info: {
      borderTop: 0,
      backgroundColor: "#63d8f1",
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },
  },

  Title: {
    DefaultStyle: {
      fontSize: "30px",
      margin: "0",
      padding: 0,
      fontWeight: "bold",
      color: "#FFFFFF",
      display: "block",
      left: "15px",
      position: "absolute",
      top: "50%",
      marginTop: "-15px",
    },
  },

  MessageWrapper: {
    DefaultStyle: {
      marginLeft: "55px",
      marginRight: "30px",
      padding: "0 12px 0 0",
      color: "#FFFFFF",
      maxWidthwidth: "89%",
    },
  },

  Dismiss: {
    DefaultStyle: {
      fontFamily: "inherit",
      fontSize: "21px",
      color: "#000",
      float: "right",
      position: "absolute",
      right: "10px",
      top: "50%",
      marginTop: "-13px",
      backgroundColor: "#FFFFFF",
      display: "block",
      borderRadius: "50%",
      opacity: ".4",
      lineHeight: "11px",
      width: "25px",
      height: "25px",
      outline: "0 !important",
      textAlign: "center",
      padding: "6px 3px 3px 3px",
      fontWeight: "300",
      marginLeft: "65px",
    },

    success: {
      // color: '#f0f5ea',
      // backgroundColor: '#a1e82c'
    },

    error: {
      // color: '#f4e9e9',
      // backgroundColor: '#fc727a'
    },

    warning: {
      // color: '#f9f6f0',
      // backgroundColor: '#ffbc67'
    },

    info: {
      // color: '#e8f0f4',
      // backgroundColor: '#63d8f1'
    },
  },

  Action: {
    DefaultStyle: {
      background: "#ffffff",
      borderRadius: "2px",
      padding: "6px 20px",
      fontWeight: "bold",
      margin: "10px 0 0 0",
      border: 0,
    },

    success: {
      backgroundColor: "#a1e82c",
      color: "#ffffff",
    },

    error: {
      backgroundColor: "#fc727a",
      color: "#ffffff",
    },

    warning: {
      backgroundColor: "#ffbc67",
      color: "#ffffff",
    },

    info: {
      backgroundColor: "#63d8f1",
      color: "#ffffff",
    },
  },

  ActionWrapper: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
    },
  },
};

//
// //
// // // For tables
// //
//
const thArray = ["ID", "Name", "Salary", "Country", "City"];
const tdArray = [
  ["1", "Dakota Rice", "$36,738", "Niger", "Oud-Turnhout"],
  ["2", "Minerva Hooper", "$23,789", "Curaçao", "Sinaai-Waas"],
  ["3", "Sage Rodriguez", "$56,142", "Netherlands", "Baileux"],
  ["4", "Philip Chaney", "$38,735", "Korea, South", "Overland Park"],
  ["5", "Doris Greene", "$63,542", "Malawi", "Feldkirchen in Kärnten"],
  ["6", "Mason Porter", "$78,615", "Chile", "Gloucester"],
];

//
// //
// // // For icons
// //
//
const iconsArray = [
  "pe-7s-album",
  "pe-7s-arc",
  "pe-7s-back-2",
  "pe-7s-bandaid",
  "pe-7s-car",
  "pe-7s-diamond",
  "pe-7s-door-lock",
  "pe-7s-eyedropper",
  "pe-7s-female",
  "pe-7s-gym",
  "pe-7s-hammer",
  "pe-7s-headphones",
  "pe-7s-helm",
  "pe-7s-hourglass",
  "pe-7s-leaf",
  "pe-7s-magic-wand",
  "pe-7s-male",
  "pe-7s-map-2",
  "pe-7s-next-2",
  "pe-7s-paint-bucket",
  "pe-7s-pendrive",
  "pe-7s-photo",
  "pe-7s-piggy",
  "pe-7s-plugin",
  "pe-7s-refresh-2",
  "pe-7s-rocket",
  "pe-7s-settings",
  "pe-7s-shield",
  "pe-7s-smile",
  "pe-7s-usb",
  "pe-7s-vector",
  "pe-7s-wine",
  "pe-7s-cloud-upload",
  "pe-7s-cash",
  "pe-7s-close",
  "pe-7s-bluetooth",
  "pe-7s-cloud-download",
  "pe-7s-way",
  "pe-7s-close-circle",
  "pe-7s-id",
  "pe-7s-angle-up",
  "pe-7s-wristwatch",
  "pe-7s-angle-up-circle",
  "pe-7s-world",
  "pe-7s-angle-right",
  "pe-7s-volume",
  "pe-7s-angle-right-circle",
  "pe-7s-users",
  "pe-7s-angle-left",
  "pe-7s-user-female",
  "pe-7s-angle-left-circle",
  "pe-7s-up-arrow",
  "pe-7s-angle-down",
  "pe-7s-switch",
  "pe-7s-angle-down-circle",
  "pe-7s-scissors",
  "pe-7s-wallet",
  "pe-7s-safe",
  "pe-7s-volume2",
  "pe-7s-volume1",
  "pe-7s-voicemail",
  "pe-7s-video",
  "pe-7s-user",
  "pe-7s-upload",
  "pe-7s-unlock",
  "pe-7s-umbrella",
  "pe-7s-trash",
  "pe-7s-tools",
  "pe-7s-timer",
  "pe-7s-ticket",
  "pe-7s-target",
  "pe-7s-sun",
  "pe-7s-study",
  "pe-7s-stopwatch",
  "pe-7s-star",
  "pe-7s-speaker",
  "pe-7s-signal",
  "pe-7s-shuffle",
  "pe-7s-shopbag",
  "pe-7s-share",
  "pe-7s-server",
  "pe-7s-search",
  "pe-7s-film",
  "pe-7s-science",
  "pe-7s-disk",
  "pe-7s-ribbon",
  "pe-7s-repeat",
  "pe-7s-refresh",
  "pe-7s-add-user",
  "pe-7s-refresh-cloud",
  "pe-7s-paperclip",
  "pe-7s-radio",
  "pe-7s-note2",
  "pe-7s-print",
  "pe-7s-network",
  "pe-7s-prev",
  "pe-7s-mute",
  "pe-7s-power",
  "pe-7s-medal",
  "pe-7s-portfolio",
  "pe-7s-like2",
  "pe-7s-plus",
  "pe-7s-left-arrow",
  "pe-7s-play",
  "pe-7s-key",
  "pe-7s-plane",
  "pe-7s-joy",
  "pe-7s-photo-gallery",
  "pe-7s-pin",
  "pe-7s-phone",
  "pe-7s-plug",
  "pe-7s-pen",
  "pe-7s-right-arrow",
  "pe-7s-paper-plane",
  "pe-7s-delete-user",
  "pe-7s-paint",
  "pe-7s-bottom-arrow",
  "pe-7s-notebook",
  "pe-7s-note",
  "pe-7s-next",
  "pe-7s-news-paper",
  "pe-7s-musiclist",
  "pe-7s-music",
  "pe-7s-mouse",
  "pe-7s-more",
  "pe-7s-moon",
  "pe-7s-monitor",
  "pe-7s-micro",
  "pe-7s-menu",
  "pe-7s-map",
  "pe-7s-map-marker",
  "pe-7s-mail",
  "pe-7s-mail-open",
  "pe-7s-mail-open-file",
  "pe-7s-magnet",
  "pe-7s-loop",
  "pe-7s-look",
  "pe-7s-lock",
  "pe-7s-lintern",
  "pe-7s-link",
  "pe-7s-like",
  "pe-7s-light",
  "pe-7s-less",
  "pe-7s-keypad",
  "pe-7s-junk",
  "pe-7s-info",
  "pe-7s-home",
  "pe-7s-help2",
  "pe-7s-help1",
  "pe-7s-graph3",
  "pe-7s-graph2",
  "pe-7s-graph1",
  "pe-7s-graph",
  "pe-7s-global",
  "pe-7s-gleam",
  "pe-7s-glasses",
  "pe-7s-gift",
  "pe-7s-folder",
  "pe-7s-flag",
  "pe-7s-filter",
  "pe-7s-file",
  "pe-7s-expand1",
  "pe-7s-exapnd2",
  "pe-7s-edit",
  "pe-7s-drop",
  "pe-7s-drawer",
  "pe-7s-download",
  "pe-7s-display2",
  "pe-7s-display1",
  "pe-7s-diskette",
  "pe-7s-date",
  "pe-7s-cup",
  "pe-7s-culture",
  "pe-7s-crop",
  "pe-7s-credit",
  "pe-7s-copy-file",
  "pe-7s-config",
  "pe-7s-compass",
  "pe-7s-comment",
  "pe-7s-coffee",
  "pe-7s-cloud",
  "pe-7s-clock",
  "pe-7s-check",
  "pe-7s-chat",
  "pe-7s-cart",
  "pe-7s-camera",
  "pe-7s-call",
  "pe-7s-calculator",
  "pe-7s-browser",
  "pe-7s-box2",
  "pe-7s-box1",
  "pe-7s-bookmarks",
  "pe-7s-bicycle",
  "pe-7s-bell",
  "pe-7s-battery",
  "pe-7s-ball",
  "pe-7s-back",
  "pe-7s-attention",
  "pe-7s-anchor",
  "pe-7s-albums",
  "pe-7s-alarm",
  "pe-7s-airplay",
];

//
// //
// // // // For dashboard's charts
// //
//
// Data for Pie Chart
var dataPie = {
  labels: ["40%", "20%", "40%"],
  series: [40, 20, 40],
};
var legendPie = {
  names: ["Open", "Bounce", "Unsubscribe"],
  types: ["info", "danger", "warning"],
};

// Data for Line Chart
var dataSales = {
  labels: ["9:00AM", "12:00AM", "3:00PM", "6:00PM", "9:00PM", "12:00PM", "3:00AM", "6:00AM"],
  series: [
    [287, 385, 490, 492, 554, 586, 698, 695],
    [67, 152, 143, 240, 287, 335, 435, 437],
    [23, 113, 67, 108, 190, 239, 307, 308],
  ],
};
var optionsSales = {
  low: 0,
  high: 800,
  showArea: false,
  height: "245px",
  axisX: {
    showGrid: false,
  },
  lineSmooth: true,
  showLine: true,
  showPoint: true,
  fullWidth: true,
  chartPadding: {
    right: 50,
  },
};
var responsiveSales = [
  [
    "screen and (max-width: 640px)",
    {
      axisX: {
        labelInterpolationFnc: function (value) {
          return value[0];
        },
      },
    },
  ],
];
var legendSales = {
  names: ["Open", "Click", "Click Second Time"],
  types: ["info", "danger", "warning"],
};

// Data for Bar Chart
var dataBar = {
  labels: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  series: [
    [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
    [412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636, 695],
  ],
};
var optionsBar = {
  seriesBarDistance: 10,
  axisX: {
    showGrid: false,
  },
  height: "245px",
};
var responsiveBar = [
  [
    "screen and (max-width: 640px)",
    {
      seriesBarDistance: 5,
      axisX: {
        labelInterpolationFnc: function (value) {
          return value[0];
        },
      },
    },
  ],
];
var legendBar = {
  names: ["Tesla Model S", "BMW 5 Series"],
  types: ["info", "danger"],
};

const fillingTimeArray = ["STANDARD", "RUSH", "ONE_HOUR"];

const suffixArray = ["DDS", "MD", "PhD", "JR", "SR", "I", "II", "III", "IV", "V", "VI"];

const EIN_title_array = {
  ceo: "CEO",
  executor: "Executor",
  owner: "Owner",
  "managing member": "Managing Member",
  "managing member/owner": "Managing Member/Owner",
  president: "President",
  other: "Other",
};

const trustee_title_array = {
  trustee: "Trustee",
  "co-trustee": "Co-trustee",
  "successor trustee": "Successor Trustee",
  executor: "Executor",
  other: "Other",
};

const representative_title_array = {
  executor: "Executor",
  administrator: "Administrator",
  personal_representative: "Personal Representative",
};

const reason_array = {
  started_business: "Started New Business",
  hired_employees: "Hired Employees",
  banking_purposes: "Banking Purposes",
  changed_organization: "Changed Type of Organization",
  purchased_business: "Purchased Business",
};

const activitySpecificProducts = {
  accommodation: {
    casino: "Casino hotel",
    hotel: "Hotel",
    motel: "Motel",
    other: "Other",
  },
  construction: {
    construction_yes: "Yes",
    construction_no: "No",
  },
  finance: {
    commodities: "Commodities broker",
    creditcard: "Credit card issuing",
    investmentadvice: "Investment advice",
    investmentclub: "Investment club",
    investmentholding: "Investment holding",
    mortgageagent: "IMortgage broker – agent for selling mortgages",
    mortgagecompany: "Mortgage company – lending funds with real estate as collateral",
    portfolio: "Portfolio management",
    sales: "Sales financing",
    securities: "Securities broker",
    trust: "Trust administration",
    venture: "Venture capital company",
    finance_other: "Other",
  },
  food: {
    bar: "Bar",
    bar2: "Bar and restaurant",
    catering: "Catering service",
    coffee: "Coffee shop",
    fast: "Fast food restaurant",
    fullservice: "Full service restaurant",
    ice: "Ice cream shop",
    mobile: "Mobile food service",
    food_other: "Other",
  },
  health: {
    health_yes: "Yes",
    health_no: "No",
  },
  insurance: {
    carrier: "I am an insurance carrier",
    agent: "I am an insurance agent or broker",
    insurance_other: "Other",
  },
  // manufacturing: null,
  real: {
    rent: "I rent or lease property that I own",
    capital: "I use capital to build property",
    sell: "I sell property for others",
    manage: "I manage real estate for others",
    real_other: "Other",
  },
  rental: {
    rentestate: "I rent, lease, or sell real estate",
    rentgoods: "I rent or lease goods",
    manageestate: "I manage real estate for others",
  },
  retail: {
    selling: "Selling goods exclusively over the Internet (includes independently selling on auction sites)",
    storefront: "Sales from a storefront",
    direct: "Direct sales",
    auction: "Auction house",
    retail_other: "Other",
  },
  social: {
    nursing: "Nursing home",
    shelter: "Shelter",
    youth: "Youth services",
    social_other: "Other",
  },
  transportation: {
    cargo: "Cargo",
    passengers: "Passengers",
    providesupport: "I provide a support activity for transportation",
  },
  wholesale: {
    wholesale_yes: "Yes",
    wholesale_no: "No",
  },
  other: {
    consulting: "Consulting",
    manufacturing: "Manufacturing",
    organization: "Organization (such as religious, environmental, social or civic, athletic, etc.)",
    rental: "Rental",
    repair: "Repair",
    other_sell: "Sell goods",
    service: "Service",
    other_other: "Other",
  },
};

const state_array = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  AA: "Armed Forces Americas",
  AE: "Armed Forces Europe, Canada, Africa and Middle East",
  AP: "Armed Forces Pacific",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  MH: "Republic of Marshall Islands",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

const month_array = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

const trust_type_array = {
  BankruptcyEstate: "Bankruptcy Estate (Individual)",
  CharitableLeadAnnuityTrust: "Charitable Lead Annuity Trust",
  CharitableLeadUnitTrust: "Charitable Lead Unitrust",
  CharitableRemainderAnnuityTrust: "Charitable Remainder Annuity Trust",
  CharitableRemainderUnitrust: "Charitable Remainder Unitrust",
  Conservatorship: "Conservatorship",
  Custodianship: "Custodianship",
  Escrow: "Escrow",
  FNMA: "FNMA (Fannie Mae)",
  GNMA: "GNMA (Ginnie Mae)",
  Guardianship: "Guardianship",
  IrrevocableTrust: "Irrevocable Trust",
  PooledIncomeFund: "Pooled Income Fund",
  QualifiedFuneralTrust: "Qualified Funeral Trust",
  Receivership: "Receivership",
  RevocableTrust: "Revocable Trust",
  SettlementFund: "Settlement Fund",
  Trust: "Trust (All Others)",
};

const vitalRelationship = [
  { value: "self", label: "Myself" },
  { value: "son", label: "Im the son" },
  { value: "daughter", label: "I'm the Daughter" },
  { value: "father", label: "I'm the Father" },
  { value: "mother", label: "I'm the Mother" },
  { value: "spouse", label: "I'm the Spouse" },
  { value: "brother", label: "I'm the Brother" },
  { value: "sister", label: "I'm the Sister" },
  { value: "grandchild", label: "I'm the Grandchild" },
  { value: "maternalGrandparent", label: "I'm the Maternal Grandparent" },
  { value: "paternalGrandpart", label: "I'm the Paternal Grandparent" },
  { value: "legalGuardian", label: "I'm the Legal Guardian" },
  { value: "legalRepresentative", label: "I'm the Legal Representative" },
  { value: "other", label: "Other" },
];

const vitalReasonOfApplying = [
  { value: "passport", label: "Passport" },
  { value: "socialSecurity", label: "Social Security" },
  { value: "retirement", label: "Retirement" },
  { value: "employment", label: "Employment" },
  { value: "workingPapers", label: "Working Papers" },
  { value: "schoolEntrance", label: "School Entrance" },
  { value: "driversLicense", label: "Drivers License" },
  { value: "marriageLicense", label: "Mariage License" },
  { value: "welfareAssistance", label: "Welfare Assistance" },
  { value: "veteransBenefits", label: "Veterans Benefits" },
  { value: "courtProceeding", label: "Court Proceeding" },
  { value: "entranceIntoArmedForces", label: "Armed Forces Entrant" },
  { value: "internationalUse", label: "International Use" },
  { value: "insurance", label: "Insurance" },
  { value: "genealogy", label: "Genealogy" },
  { value: "other", label: "Other" },
];

const designatorsLLC = ["LLC", "L.L.C.", "Limited Liability Company", "Limited Liability Co.", "LTD Liability Company"];

const TRADEMARK_REGISTER_CODES = [
  "TRADEMARK_RegisterTrademark",
  "TRADEMARK_RegisterTrademarkV2",
  "INC_RegisterTrademark",
];

const EIN_MAIN_CODES = [
  "EIN_SoleProp",
  "EIN_LLC",
  "EIN_Partnership",
  "EIN_CCorp",
  "EIN_SCorp",
  "EIN_Trust",
  "EIN_Estate",
  "EIN_PSC",
  "EIN_NonProfit",
  "EIN_Church",
];

const TAB_CODES = {
  order: "Order",
  customer: "Customer",
  einInfo: "EIN Info",
  llcIncInfo: "LLC Inc Info",
  vitalRecord: "Vital Record",
  trademarkInfo: "Trademark Info",
  amendmentInfo: "Amendment Info",
  boiInfo: "BOI Info",
  defaultInfo: "Info",
  actions: "Actions",
  assessment: "Assessment",
  logoInfo: "Logo Info",
};

export const EXTENSION_REASON_OPTIONS = [
  {
    value: "Product or service research or development",
    title: "Product or service research or development",
  },
  { value: "Market research", title: "Market research" },
  { value: "Manufacturing activities", title: "Manufacturing activities" },
  { value: "Promotional activities", title: "Promotional activities" },
  {
    value: "Steps to acquire distributors",
    title: "Steps to acquire distributors",
  },
  {
    value: "Steps to obtain governmental approval",
    title: "Steps to obtain governmental approval",
  },
  {
    value: "Training regarding standards",
    title: "Training regarding standards (only for collective or certification marks)",
  },
  {
    value: "Steps to acquire authorized users",
    title: "Steps to acquire authorized users (only for certification marks)",
  },
  {
    value: "Steps to acquire members",
    title: "Steps to acquire members (only for collective works)",
  },
  {
    value: "Development of standards",
    title: "Development of standards (only for collective or certification marks)",
  },
  { value: "Other", title: "Other" },
];

const rejectionReasonArray = {
  MISC: { value: "MISC", title: "Misc" },
  ALTERNATIVE_NAME: { value: "ALTERNATIVE_NAME", title: "Alternative Name" },
};

const reFileAbilityArray = {
  FREE_RE_FILE: { value: "FREE_RE_FILE", title: "Free Re-File" },
  FREE_RE_FILE_NOT_AVAILABLE: { value: "FREE_RE_FILE_NOT_AVAILABLE", title: "Free Re-File Not Available" },
};

const LETTERS_TYPE = {
  AS_IT_IS: "As it is",
  UPPERCASE: "In CAPITAL LETTERS",
  LOWERCASE: "In small letters",
};

const LETTERS_TYPE_OPTIONS = [
  { label: "As it is", value: "AS_IT_IS" },
  { label: "In CAPITAL LETTERS", value: "UPPERCASE" },
  { label: "In small letters", value: "LOWERCASE" },
];

const LOGO_CATEGORY = {
  JUST_FONT: "Just font",
  FONT_WITH_MEANING: "Font + Meaning",
  HANDMADE: "Handmade",
  INITIALS: "Initials",
  ABSTRACT_GRAPHIC: "Abstract graphic",
  SILHOUETTE: "Silhouette",
  GEOMETRIC_SYMBOL: "Geometric symbol",
  ILLUSTRATED_SYMBOL: "Illustrated symbol",
  EMBLEM_AND_CRESTS: "Emblem and Crests",
  MASCOT: "Mascot",
};

const LOGO_CATEGORY_OPTIONS = [
  { label: "Just font", value: "JUST_FONT" },
  { label: "Font + Meaning", value: "FONT_WITH_MEANING" },
  { label: "Handmade", value: "HANDMADE" },
  { label: "Initials", value: "INITIALS" },
  { label: "Abstract graphic", value: "ABSTRACT_GRAPHIC" },
  { label: "Silhouette", value: "SILHOUETTE" },
  { label: "Geometric symbol", value: "GEOMETRIC_SYMBOL" },
  { label: "Illustrated symbol", value: "ILLUSTRATED_SYMBOL" },
  { label: "Emblem and Crests", value: "EMBLEM_AND_CRESTS" },
  { label: "Mascot", value: "MASCOT" },
];

const LOGO_USE = {
  WEBSITE: "Website",
  BUSINESS_STATIONARY_PRINTING: "Business Stationery Printing",
  ADVERTISING: "Signage / Billboards / Advertisement",
  ONLINE_SOCIAL_MEDIA: "Online Social Media",
  TSHIRT_PRINTING: "T-Shirt Printing",
  CAR_WRAP: "Car Wrap / Vinyls",
  EMBROIDERY: "Embroidery",
  PACKAGING: "Packaging",
  APP_ICON: "App Icon",
  FLYER: "Flyer / Brochure",
  OTHER: "Other",
};

const LOGO_USE_OPTIONS = [
  { label: "Website", value: "WEBSITE" },
  { label: "Business Stationery Printing", value: "BUSINESS_STATIONARY_PRINTING" },
  { label: "Signage / Billboards / Advertisement", value: "ADVERTISING" },
  { label: "Online Social Media", value: "ONLINE_SOCIAL_MEDIA" },
  { label: "T-Shirt Printing", value: "TSHIRT_PRINTING" },
  { label: "Car Wrap / Vinyls", value: "CAR_WRAP" },
  { label: "Embroidery", value: "EMBROIDERY" },
  { label: "Packaging", value: "PACKAGING" },
  { label: "App Icon", value: "APP_ICON" },
  { label: "Flyer / Brochure", value: "FLYER" },
  { label: "Other", value: "OTHER" },
];

const CATEGORIES_ARRAY = [
  { code: "EIN", value: "EIN" },
  { code: "EIN_INC", value: "EIN – INC" },
  { code: "INC", value: "Incorpify" },
  { code: "INC_States", value: "Incorporations States" },
  { code: "TRADEMARKS", value: "Trademarks" },
  { code: "VITAL", value: "Vital" },
  { code: "ACCOUNTING", value: "Accounting" },
];

const CATEGORIES_OBJ = Object.freeze({
  ein: "EIN",
  einInc: "EIN_INC",
  inc: "INC",
  incStates: "INC_States",
  trademarks: "TRADEMARKS",
  vital: "VITAL",
  accounting: "ACCOUNTING",
});

const PRODUCT_CODES = Object.freeze({
  // EIN
  einBoi: "EIN_BOI",
  einCCorp: "EIN_CCorp",
  einChurch: "EIN_Church",
  einEstate: "EIN_Estate",
  einINC: "EIN_INC",
  einLLC: "EIN_LLC",
  einLLCIncorporation: "EIN_LLCIncorporation",
  einNonProfit: "EIN_NonProfit",
  einPartnership: "EIN_Partnership",
  einPSC: "EIN_PSC",
  einSCorp: "EIN_SCorp",
  einSoleProp: "EIN_SoleProp",
  einTrust: "EIN_Trust",
  einDBA: "EIN_DBA",
  einDBAStateFee: "EIN_DBA_State_Fee",
  einCertifiedCopy: "EIN_CertifiedCopy",
  einCertifiedCopyStateFee: "EIN_CertifiedCopyStateFee",
  // INC
  incAnnualComplianceReport: "INC_AnnualComplianceReport",
  incAnnualReport: "INC_AnnualReport",
  incAnnualReportStateFee: "INC_AnnualReportStateFee",
  incBankingResolution: "INC_BankingResolution",
  incBoi: "INC_BOI",
  incBusinessLicense: "INC_BusinessLicenses",
  incCertificateOfGoodStanding: "INC_CertificateOfGoodStanding",
  incCertificateOfGoodStandingStateFee: "INC_CertificateOfGoodStandingStateFee",
  incCertifiedCopy: "INC_CertifiedCopy",
  incCertifiedCopyStateFee: "INC_CertifiedCopyStateFee",
  incDissolution: "INC_Dissolution",
  incDissolutionFilingFee: "INC_DissolutionFilingFee",
  incEIN: "INC_EIN",
  incLLCIncorporation: "INC_LLCIncorporation",
  incLLCIncorporationV2: "INC_LLCIncorporationV2",
  incOperatingAgreement: "INC_OperatingAgreement",
  incRegisteredAgent: "INC_RegisteredAgent",
  incRegisterTrademark: "INC_RegisterTrademark",
  incResidentAgent: "INC_ResidentAgent",
  incRushProcessing: "INC_Rush_Processing",
  incSalesTaxPermit: "INC_SalesTaxPermit",
  incSalesTaxPermitStateFee: "INC_SalesTaxPermitStateFee",
  incSameDayProcessing: "INC_Same_Day_Processing",
  incSameDayProcessingV2: "INC_Same_Day_ProcessingV2",
  incStateIncorporationFee: "INC_StateIncorporationFee",
  incTrademarkBasicSearch: "INC_Trademark_Search_Basic",
  incTrademarkMonitoring: "INC_Trademark_Monitoring",
  incUsptoFillingFee: "INC_USPTOFilingFee",
  incDBA: "INC_DBA",
  incDBAStateFee: "INC_DBA_State_Fee",
  // Inc_States
  incStatesAnnualComplianceReport: "INC_States_AnnualComplianceReport",
  incStatesAnnualReport: "INC_States_AnnualReport",
  incStatesAnnualReportStateFee: "INC_States_AnnualReportStateFee",
  incStatesBankingResolution: "INC_States_BankingResolution",
  incStatesBoi: "INC_States_BOI",
  incStatesBusinessLicenses: "INC_States_BusinessLicenses",
  incStatesCertificateOfGoodStanding: "INC_States_CertificateOfGoodStanding",
  incStatesCertificateOfGoodStandingStateFee: "INC_States_CertificateOfGoodStandingStateFee",
  incStatesCertifiedCopy: "INC_States_CertifiedCopy",
  incStatesCertifiedCopyStateFee: "INC_States_CertifiedCopyStateFee",
  incStatesDissolution: "INC_States_Dissolution",
  incStatesDissolutionFilingFee: "INC_States_DissolutionFilingFee",
  incStatesEIN: "INC_States_EIN",
  incStatesLLCIncorporation: "INC_States_LLCIncorporation",
  incStatesLLCIncorporationV2: "INC_States_LLCIncorporationV2",
  incStatesLLCKit: "INC_States_LLCKit",
  incStatesLLCTaxFiling: "INC_States_LLC_Tax_filing",
  incStatesMonthlyBookkeeping: "INC_States_Monthly_bookkeeping",
  incStatesOperatingAgreement: "INC_States_OperatingAgreement",
  incStatesPersonalTaxFiling: "INC_States_Personal_Tax_filing",
  incStatesRegisteredAgent: "INC_States_RegisteredAgent",
  incStatesRegisterTrademark: "INC_States_RegisterTrademark",
  incStatesResidentAgent: "INC_States_ResidentAgent",
  incStatesRushProcessing: "INC_States_Rush_Processing",
  incStatesSalesTaxPermit: "INC_States_SalesTaxPermit",
  incStatesSalesTaxPermitStateFee: "INC_States_SalesTaxPermitStateFee",
  incStatesSameDayProcessing: "INC_States_Same_Day_Processing",
  incStatesSameDayProcessingV2: "INC_States_Same_Day_ProcessingV2",
  incStatesSCorp: "INC_States_S_Corp",
  incStatesSoleProprietorTaxFiling: "INC_States_Sole_Proprietor_Tax_filing",
  incStatesStateIncorporationFee: "INC_States_StateIncorporationFee",
  incStatesTrademarkBasicSearch: "INC_States_Trademark_Search_Basic",
  incStatesTrademarkMonitoring: "INC_States_Trademark_Monitoring",
  incStatesUSPTOFilingFee: "INC_States_USPTOFilingFee",
  incStatesDBA: "INC_States_DBA",
  incStatesDBAStateFee: "INC_States_DBA_State_Fee",
  // Trademark
  tmRegisterTrademark: "TRADEMARK_RegisterTrademark",
  tmTrademarkMonitoring: "TRADEMARK_Trademark_Monitoring",
  tmTrademarkBasicSearch: "TRADEMARK_Trademark_Search_Basic",
});

const MONTHLY_VOLUME = {
  MV_0_5000: "$0 - $5,000",
  MV_5001_10000: "$5,001 - $10,000",
  MV_10001_25000: "$10,001 - $25,000",
  MV_25001_50000: "$25,001 - $50,000",
  MV_50001_100000: "$50,001 - $100,000",
  MV_100001_250000: "$100,001 - $250,000",
  MV_250001_MAX: "$250,000+",
};

export {
  style, // For notifications (App container and Notifications view)
  thArray,
  tdArray, // For tables (TableList view)
  iconsArray, // For icons (Icons view)
  dataPie,
  legendPie,
  dataSales,
  optionsSales,
  responsiveSales,
  legendSales,
  dataBar,
  optionsBar,
  responsiveBar,
  legendBar, // For charts (Dashboard view),
  fillingTimeArray,
  suffixArray,
  EIN_title_array,
  reason_array,
  state_array,
  month_array,
  trust_type_array,
  trustee_title_array,
  activitySpecificProducts,
  representative_title_array,
  vitalReasonOfApplying,
  vitalRelationship,
  designatorsLLC,
  TRADEMARK_REGISTER_CODES,
  EIN_MAIN_CODES,
  TAB_CODES,
  rejectionReasonArray,
  reFileAbilityArray,
  LETTERS_TYPE,
  LETTERS_TYPE_OPTIONS,
  LOGO_CATEGORY,
  LOGO_CATEGORY_OPTIONS,
  LOGO_USE,
  LOGO_USE_OPTIONS,
  CATEGORIES_OBJ,
  CATEGORIES_ARRAY,
  PRODUCT_CODES,
  MONTHLY_VOLUME,
};
