import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { getAvailableCategory, store, history } from "_helpers";

import AdminLayout from "layouts/Admin";
import Redirect2FA from "views/Redirect2FA";
import { Login } from "views/Login";
import { PrivateRoute } from "routes/PrivateRoute";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard-react.scss";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/css/graph.css";
import "./assets/css/collapse.css";
import "./assets/css/custom.css";

import "./assets/css/index.css";

const user = localStorage.getItem("user");
const categoryToRedirect = user && getAvailableCategory(JSON.parse(user));

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Router history={history}>
        <Switch>
          <PrivateRoute path="/admin" component={AdminLayout} />
          <Route path="/login" component={Login} />
          <Route path="/2fa" component={Redirect2FA} />
          <Redirect from="*" to={categoryToRedirect ? `/admin/orderlists/${categoryToRedirect}` : "/admin/dashboard"} />
        </Switch>
      </Router>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
